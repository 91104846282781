import {
  Link
} from 'gatsby'
import React from 'react'
import profile from "../assets/2021_headshot_round.png" // Tell Webpack this JS file uses this image


const Bio = () => ( <
  div >
  <
  h4 > About the Author < /h4> <
  div className = "flex-container" >
  <
  div className = "col-2" >
  <img src = {profile
  }
  alt = "Violet"
  id = "profile-pic" / >
  <
  /div> <
  div className = "col-10" >
  <
  p > Violet loves making maps and satisfying data visualizaitons. 
  Violet also love getting down and dirty with data. < Link to = "/about/" > See more about her here. < /Link> < /
  p > < /
  div > <
  /div> < /
  div >
)



export default Bio